<template>
  <template v-if="user !== null">
    <page-header title="Просмотр пользователя" back>
      <user-context-menu v-if="!user.deleted" :user="user" :hook="reload" view="buttons" />
    </page-header>

    <q-card v-if="user.deleted" class="q-pa-lg">
      Пользователь удалён.
    </q-card>
    <template v-else>
      <q-card class="user">
        <div class="user__header">
          <div class="user__avatar">
            <user-avatar :user="user" />
          </div>
          <div class="user__name">
            {{ user.name }}
          </div>
        </div>

        <div v-if="user.email" class="user__field row">
          <div class="user__field-key">
            Электронная почта
          </div>
          <div class="user__field-value">
            {{ user.email }}
          </div>
        </div>

        <div v-if="user.role" class="user__field row">
          <div class="user__field-key">
            Роль в системе
          </div>
          <div class="user__field-value">
            {{ user.role.title }}
          </div>
        </div>

        <div v-if="user.createdAt" class="user__field row">
          <div class="user__field-key">
            Дата регистрации
          </div>
          <div class="user__field-value" v-html="formatDate(user.createdAt)" />
        </div>

        <div class="user__field row">
          <div class="user__field-key">
            Дата последнего входа
          </div>
          <div class="user__field-value">
            <div v-if="user.visitedAt" v-html="formatDate(user.visitedAt)" />
            <div v-else>Нет.</div>
          </div>
        </div>

        <div class="user__field row">
          <div class="user__field-key">
            Статус
          </div>
          <div class="user__field-value">
            <span v-if="user.blocked" class="user-status user-status--blocked">Заблокирован</span>
            <span v-else class="user-status user-status--active">Активен</span>
          </div>
        </div>
      </q-card>

      <q-card v-if="user.role.id === 'student'" class="q-mt-lg q-pa-md">
        <router-link v-if="viewsTotal > 0" :to="{name: 'views_user_list', params: {id: user.id}}">Просмотрено {{ viewsTotal }} {{ pluralize(viewsTotal, 'слово', 'слова', 'слов') }}</router-link>
        <template v-else>Нет просмотренных слов.</template>
      </q-card>
    </template>
  </template>
</template>

<script>
import loading from '../../loading.js'
import { api as usersApi } from '../../api/users.js'
import { api as viewsApi } from '../../api/views.js'
import PageHeader from '../../blocks/PageHeader.vue'
import UserContextMenu from '../../blocks/users/UserContextMenu.vue'
import UserAvatar from '../../blocks/UserAvatar.vue'

export default {
  components: {
    PageHeader,
    UserContextMenu,
    UserAvatar,
  },
  async beforeRouteEnter (to, from, next) {
    loading.start()
    const user = await usersApi.find(to.params.id)
    if (!user) {
      next(false)
      return
    }
    const views = await viewsApi.findBy({ user, limit: 1})
    next(vm => {
      vm.user = user
      vm.viewsTotal = views.meta.total
    })
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      user: null,
      deleted: false,
      viewsTotal: 0,
    }
  },
  methods: {
    async reload(user) {
      this.user = user
    },
    formatDate(date) {
      const format = value => value < 10 ? `0${value}` : value
      let day = format(date.getDate())
      let month = format(date.getMonth() + 1)
      let year = date.getFullYear()
      let hours = format(date.getHours())
      let minutes = format(date.getMinutes())
      return `${day}.${month}.${year}&nbsp;&nbsp;${hours}:${minutes}`
    },
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.user {
  &__header {
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  &__avatar {
    margin-right: 24px;
    @media (max-width: $breakpoint-xs-max) {
      margin-right: 12px;
    }
  }

  &__name {
    flex: 1;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: 24px;
  }

  &__actions {
    .action {
      font-family: Raleway, sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #2C7DDC;

      @media (max-width: $breakpoint-xs-max) {
        & {
          height: 34px;
          width: 34px;
          margin-left: 12px;
        }

        .action__text {
          display: none;
        }
      }
    }
  }

  &__field {
    display: flex;
    flex-wrap: nowrap;
    border-top: 2px solid #F5F6FA;

    @media (max-width: $breakpoint-xs-max) {
      flex-direction: column;
    }
  }

  &__field-key {
    padding: 22px 20px;
    min-height: 60px;
    flex: 0 0 200px;
    width: 200px;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);

    @media (max-width: $breakpoint-xs-max) {
      flex-basis: auto;
      font-size: 11px;
      padding: 15px 20px 5px;
      min-height: auto;
    }
  }

  &__field-value {
    padding: 19px;
    @media (max-width: $breakpoint-xs-max) {
      padding: 5px 20px 15px;
    }
  }
}

.user-status {
  position: relative;
  color: #2AB68C;
  padding-left: 15px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #2AB68C;
    border-radius: 4px;
  }

  &--blocked {
    color: #F36565;

    &::before {
      background: #F36565;
    }
  }
}
</style>
